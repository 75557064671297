export const uppercaseWords = (str: string): string =>
  str
    .replace('-', ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

export const createValidRegexPattern = (str: string): string => {
  const invalid = /[°"§%()\[\]{}=\\?´`'#<>|,;.:+_-]+/g;
  let queryParts: string[] = [];
  for (let i = 0; i < str.length; i++) {
    const isBad = str[i].match(invalid);
    if (isBad) {
      const val = '[' + str[i] + ']';
      queryParts.push(val);
    } else {
      queryParts.push(str[i]);
    }
  }
  const validQuery = queryParts.join('');
  return validQuery;
};

export function classNames(...classes: any[]): string {
  return classes.filter(Boolean).join(' ');
}

export const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const breakWordsIntoLines = (
  words: string,
  charWidth: number,
  containerWidth: number
): string[] => {
  const wordsArray = words.split(' ');
  const lines = [];
  let currentLine = '';
  let currentLineWidth = 0;
  const maxLineWidth = Math.floor(containerWidth / charWidth);

  wordsArray.forEach(word => {
    const wordLength = word.length;

    if (currentLineWidth + wordLength <= maxLineWidth) {
      if (currentLine) {
        currentLine += ' ';
        currentLineWidth++;
      }
      currentLine += word;
      currentLineWidth += wordLength;
    } else {
      lines.push(currentLine);
      currentLine = word;
      currentLineWidth = wordLength;
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  return lines;
};
