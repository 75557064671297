import { CreatePageProps } from 'modules/pages/use-cases/CreatePage/types';
import { FetchService } from './FetchService';

import { ListPageRoutesResponse } from 'modules/pages/use-cases/ListPageRoutes/types';
import {
  PagePaginateRequest,
  PagePaginateResponse
} from 'modules/pages/use-cases/PaginatePages/types';
import { PageView } from 'modules/pages/use-cases/GetPage/types';
import { UpdatePageProps } from 'modules/pages/use-cases/UpdatePage/types';
import { UseCaseCreatedResponse } from 'common/core/UseCase';

export const getPage = (url: string) => FetchService<PageView>('GET', url);

export const paginatePages = (request: PagePaginateRequest) =>
  FetchService<PagePaginateResponse, PagePaginateRequest>(
    'GET',
    `/api/pages/`,
    request
  );

export const updatePage = (id: string, data: UpdatePageProps) =>
  FetchService<boolean, UpdatePageProps>('PUT', `/api/pages/${id}/`, data);

export const createPage = (data: CreatePageProps) =>
  FetchService<UseCaseCreatedResponse, CreatePageProps>(
    'POST',
    `/api/pages/`,
    data
  );

export const deletePage = (id: string) =>
  FetchService<boolean>('DELETE', `/api/pages/${id}/`);

export const duplicatePage = (id: string) => {
  FetchService<boolean, UpdatePageProps>('PUT', `/api/pages/${id}/duplicate/`);
};

export const listPages = (url: string) =>
  FetchService<ListPageRoutesResponse>('GET', url);
