import useSWR, { Fetcher, Key } from 'swr';

export type { Key, Fetcher };

export default function useService<Data = unknown, Error = unknown>(
  key: Key,
  service: any
): {
  data?: Data;
  error?: Error;
  isLoading: boolean;
  mutate: (props: Data) => void;
} {
  const { data, isLoading, mutate, error } = useSWR<Data, Error>(key, service, {
    onErrorRetry: (error, key, _config, revalidate, { retryCount }) => {
      // Never retry on 404.
      //@ts-ignore
      if (error.status === 404 || error.status === 500) return;

      // Never retry for a specific key.
      if (key === '/api/notifications') return;

      // Only retry up to 10 times.
      if (retryCount >= 10) return;

      // Retry after 5 seconds.
      setTimeout(() => revalidate({ retryCount }), 5000);
    }
  });
  return {
    data,
    error,
    isLoading,
    mutate
  };
}
