import { BlockRenderer } from './BlockRenderer';
import { BlockSection } from './types';
import { PageView } from 'modules/pages/use-cases/GetPage/types';

interface PageRendererProps {
  page?: PageView;
  sections: BlockSection[];
}

export const PageRenderer = (props: PageRendererProps) => {
  const { sections, page } = props;
  return (
    <section className="relative w-full">
      {sections.map(section => (
        <BlockRenderer
          key={section.id}
          block={{
            ...section,
            content: {
              ...section.content,
              ...(page &&
                (page.goal || page.totalRaised) && {
                  goal: page.goal,
                  totalRaised: page.totalRaised,
                  name: page.title,
                  campaignId: page.campaignId,
                  slug: page.slug,
                  enableMatching: page.enableMatching,
                  matchingMessage: page.matchingMessage,
                  matchingLimit: page.matchingLimit
                })
            }
          }}
        />
      ))}
    </section>
  );
};
