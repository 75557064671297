import { LoadingSpinner } from '../LoadingSpinner';

const Loader = () => {
  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default Loader;
