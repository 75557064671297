import { AnimatePresence } from 'framer-motion';
import { FixedArrowButton } from 'components/button/FixedArrowButton';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/string';
import { useRouter } from 'next/router';
import styled from 'styled-components';

interface DonationPopUpProps {
  open: boolean;
  giveUrl: string;
  handleClose: () => void;
}

const DonationPopUp = (props: DonationPopUpProps) => {
  const { open, handleClose, giveUrl } = props;
  const router = useRouter();
  const StyledDiv = styled.div`
    box-shadow: 0px 0px 30px 0px rgba(2, 2, 2, 1);
  `;

  return (
    <AnimatePresence>
      {open && (
        <div className={'visible relative z-50 shadow-2xl'}>
          <StyledDiv
            className={classNames(
              'fixed bg-white w-[300px] h-[auto] right-[2vw] p-[18px]rounded border-8 border-solid border-black flex items-center justify-center transition ease-in duration-500',
              open ? 'bottom-[0vh] opacity-1' : '-bottom-[521px] opacity-0'
            )}
          >
            <div className="relative mx-auto w-full rounded p-10">
              <div className="mt-3 pt-10 text-center sm:mt-0 sm:text-left bg-white">
                <h3 className="uppercase text-[40px] font-semibold text-center leading-[2.5rem] text-gray-900">
                  Give Today. Build Families.
                </h3>
              </div>
              <button
                type="button"
                className="absolute -right-4 -top-4 text-gray-400 hover:text-gray-500 bg-black border rounded-full border-white border-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="w-full text-center bg-white overflow-hidden px-4 pb-8">
                <div className="w-full pt-[25px]">
                  Your gift helps us reach more kids and build more families
                  across the country.
                </div>
                <div className="flex flex-shrink-0 items-center my-4 ">
                  <FixedArrowButton
                    styletype="primary"
                    label="Give Here"
                    className="uppercase"
                    hideArrow={true}
                    onClick={() => router.push(giveUrl)}
                  />
                </div>
              </div>
            </div>
          </StyledDiv>
        </div>
      )}
    </AnimatePresence>
  );
};

export default DonationPopUp;
