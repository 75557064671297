import { BlockComponentMap } from 'components/editor/BlockMapper';
import React from 'react';

export const BlockRenderer = ({ block }: any) => {
  const BlockComponent = Object.prototype.hasOwnProperty.call(
    BlockComponentMap,
    block.id
  )
    ? BlockComponentMap[block.id]
    : null;
  if (!BlockComponent) return null;
  return React.createElement(BlockComponent, { ...block.content });
};
