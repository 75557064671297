import { AnimatePresence } from 'framer-motion';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/string';
import Image from 'next/image';
import Link from 'next/link';
import NatoBanner from './assets/nato-banner-2024.jpg';
import styled from 'styled-components';
import tw from 'tailwind-styled-components';

interface NatoPopUpProps {
  open: boolean;
  rsvp: boolean;
  handleClose: () => void;
}

const StyledButton = tw(Link)`
  text-black border-[#BA9E43] bg-[#BA9E43] hover:bg-amber-100 group
  text-base px-9 py-1 inline-flex text-center items-center justify-center focus:outline-none focus:ring-offset-2 font-medium focus:ring-2 bordertransition-all ease-in delay-100`;

const NatoPopUp = (props: NatoPopUpProps) => {
  const { open, handleClose, rsvp } = props;
  const StyledDiv = styled.div`
    box-shadow: 0px 0px 30px 0px rgba(2, 2, 2, 1);
  `;

  return (
    <AnimatePresence>
      {open && (
        <div className={'visible relative z-50 shadow-2xl'}>
          <StyledDiv
            className={classNames(
              'fixed bg-black w-[300px] h-[auto] right-[2vw] p-[18px]rounded border-8 border-solid border-[#BA9E43] flex items-center justify-center transition ease-in duration-500',
              open ? 'bottom-[0vh] opacity-1' : '-bottom-[521px] opacity-0'
            )}
          >
            <div className="relative mx-auto w-full rounded">
              <div className="mt-0 text-center bg-black">
                <Image src={NatoBanner} alt="Night At The Oscars" />
              </div>
              <div className="mt-3 pt-6 text-center sm:mt-0 sm:text-left bg-black">
                <h3 className="uppercase text-[40px] font-semibold text-center leading-[2.5rem] text-white">
                  {rsvp
                    ? `Join us for this year's Night at the Oscars!`
                    : `NIGHT AT THE OSCARS`}
                </h3>
              </div>
              <button
                type="button"
                className="absolute -right-4 -top-4 text-gray-400 hover:text-gray-500 bg-black rounded-full border-white border-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="w-full text-center bg-black text-white overflow-hidden px-14 pb-20">
                <div className="w-full pt-[25px]">
                  {rsvp
                    ? 'Learn more about the event here!'
                    : 'Consider becoming a supporter this fall to help us reach more kids across the country!'}
                </div>
                <div className="flex flex-shrink-0 items-center my-4 justify-center">
                  {rsvp ? (
                    <StyledButton
                      href="https://thereelhopeproject.ticketspice.com/nato2024"
                      target="_blank"
                    >
                      RSVP
                    </StyledButton>
                  ) : (
                    <StyledButton href="/2024nightattheoscars/">
                      Give Here
                    </StyledButton>
                  )}
                </div>
              </div>
            </div>
          </StyledDiv>
        </div>
      )}
    </AnimatePresence>
  );
};

export default NatoPopUp;
