import { ListPageRoutesResponse } from 'modules/pages/use-cases/ListPageRoutes/types';
import { ScrollToTop } from './ScrollToTop';
import { listPages } from 'services/pages';
import { navConfig } from 'config/nav';
import { useRouter } from 'next/router';
import Footer from './Footer';
import Loader from './Loader';

import Navbar from './Navbar';
import React, { Suspense, useEffect, useState } from 'react';
import useService from 'hooks/useService';
interface LayoutProps {
  children: JSX.Element;
}
export default function Layout({ children }: LayoutProps) {
  const router = useRouter();
  const [nav] = useState(navConfig['default']);
  const [showHeaderFooter, setShowHeaderFooter] = useState<boolean>(true);
  const [giveUrl, setGiveUrl] = useState<string>('/give/');
  const { data, isLoading } = useService<ListPageRoutesResponse>(
    `/api/pages/list/`,
    listPages
  );
  const checkPathForCampaign = (path: string) => {
    if (data && data.pages) {
      const campaignPages = data.pages.filter(p => p.isCampaign);
      const campaign = campaignPages.find(p => `/${p.slug}/` === path);
      if (campaign) {
        setShowHeaderFooter(false);
      } else {
        const isPartyCampaign = campaignPages.find(p =>
          path.includes(`/${p.slug}/`)
        );
        if (isPartyCampaign) {
          setShowHeaderFooter(false);
        } else {
          setShowHeaderFooter(true);
        }
      }
    }
  };
  useEffect(() => {
    if (data && data.pages) {
      const defaultCampaign = data.pages.find(p => p.isCampaign && p.isDefault);
      if (defaultCampaign) {
        setGiveUrl(`/${defaultCampaign.slug}/`);
      }
      const currentPath = router.asPath;
      checkPathForCampaign(currentPath);
    }
  }, [data]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      checkPathForCampaign(url);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    const currentPath = router.asPath;
    checkPathForCampaign(currentPath);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  if ((isLoading || !data) && router.asPath !== '/') return <Loader />;

  return (
    <>
      {showHeaderFooter && (
        <Navbar
          navItems={nav}
          center
          logo={
            'https://www.thereelhopeproject.org/TRHP-Black-Transparent-Web-Logo.png'
          }
          giveLink={giveUrl}
        />
      )}
      <main>
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </main>
      <ScrollToTop />
      {showHeaderFooter && <Footer />}
    </>
  );
}
